<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容区域 -->
    <div class="content">
      <div class="content_1">
          <img src="@/assets/join/招募配图.png" alt="">
          <div class="recruit_1">
            <h2>人才招募</h2>
            <p @click="$router.push('/zh/joinId')" style="overflow: hidden;">查看详情</p>
          </div>
      </div>
      <div class="content_2">
          <img src="@/assets/join/校园招募.png" alt="">
          <div class="recruit_2">
            <h2>校园招募 </h2>
            <p @click="$router.push('/zh/school')" style="overflow: hidden;">查看详情</p>
          </div>
      </div>
      <div class="content_1">
          <img src="@/assets/join/人才理念.jpg" alt="">
            <div class="recruit_1">
              <h2>人才理念</h2>
            <p @click="$router.push('/zh/idea')" style="overflow: hidden;">查看详情</p>
          </div>
      </div>
    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
      <!-- 轮播图 -->
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item>
           <img src="@/assets/homePage/banner.png" class="images">
        </van-swipe-item>
      </van-swipe>
        <!-- 内容区域 -->
            <div class="content">
      <div class="content_1">
          <img src="@/assets/join/招募配图.png" alt="">
          <div class="recruit_1">
            <h2>人才招募</h2>
            <p @click="$router.push('/zh/joinId')" style="overflow: hidden;">查看详情</p>
          </div>
      </div>
      <div class="content_2">
          <img src="@/assets/join/校园招募.png" alt="">
          <div class="recruit_2">
            <h2>校园招募</h2>
            <p @click="$router.push('/zh/school')" style="overflow: hidden;">查看详情</p>
          </div>
      </div>
      <div class="content_1">
          <img src="@/assets/join/人才理念.jpg" alt="">
            <div class="recruit_1">
              <h2>人才理念</h2>
            <p @click="$router.push('/zh/idea')" style="overflow: hidden;">查看详情</p>
          </div>
      </div>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRouter } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  components: { foot, headers,move_header,move_foot },

  setup() {
    const router = useRouter();
    const state = reactive({

    });
    onMounted(() => {
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="less">
.el-carousel{
    max-width: 1920px;
    margin: 0 auto;
}
.box {
    
  width: 100%;
  .content{
    max-width: 1920px;
    margin: 0 auto;
    width: 70%;
    .content_1,.content_2{
      position: relative;
      width: 100%;
      height: 450px;
      margin: 50px 0;
      img{
        width: 100%;
        z-index: 1;
      }
      .recruit_1{
        position: absolute;
        right: 100px;
        top: 150px;

        h2{
          margin: 0;padding: 0;
          text-align: center;
        }
      }
      .recruit_2{
        position: absolute;
        left: 100px;
        top: 150px;
        h2{
          margin: 0;padding: 0;
          text-align: center;
        }
      }
       p {
    text-align: center;
    margin: 40px 20px;
    border: 1px solid #ccc;
    height: 47px;
    line-height: 47px;
    width: 150px;
    cursor: pointer;
    float: left;
    overflow: hidden;
    z-index: 9;
  }

  p:hover {
    background-color: #ef3e2e;
    color: #fff;
  }
    }
  }
}
.images{
   width: 100%; height: auto; max-height: 600px;
}
::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
    .box {
    display: none;
  }

  ._div {
    display: block;
    .content{
    max-width: 1920px;
    margin: 0 auto;
    width: 95%;
    .content_1,.content_2{
      position: relative;
      width: 100%;
      height: auto;
      font-size: 55px;
      margin: 50px 0;
      img{
        width: 100%;
        z-index: 1;
      }
      .recruit_1{
        position: absolute;
        right: 160px;
        top: 150px;

        h2{
          margin: 0;padding: 0;
          text-align: center;
        }
      }
      .recruit_2{
        position: absolute;
        left: 100px;
        top: 150px;
        h2{
          margin: 0;padding: 0;
          text-align: center;
        }
      }
       p {
    text-align: center;
    margin: 40px 20px;
    border: 1px solid #ccc;
    height: 120px;
    line-height: 120px;
    width: 400px;
    cursor: pointer;
    float: left;
    overflow: hidden;
    z-index: 9;
  }

  p:hover {
    background-color: #ef3e2e;
    color: #fff;
  }
    }
  }
  }
}

</style>